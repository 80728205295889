export default function getGlobal()
{

    //return "https://digital.currenmexico.com/cotizador/"  // Este es el acceso global

    return "https://digital.digitalshopcenter.com.mx/cotizador2/"  // Este es el acceso global ( Servidor de digital )

    //return  "http://localhost:3008/cotizador/"


}