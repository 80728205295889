import { useState } from "react";
//import * as XLSX from 'xlsx';

import axios from "axios";
//import getGlobal from "../../setglobal";
import getGlobal from "../setglobal";
import ExcelBack from "./ExcelBack";
import ExcelLoadOk from "./ExcelLoadOk";

function ExcelLoad() {

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [data,setData] = useState( null ) ;
  const [back,setBack] = useState( false ) ;
  const [loaded,setLoaded] = useState(false) ;

  // submit state
  const [excelData, setExcelData] = useState(null);

  // onchange event
  const handleFile=(e)=>{
    //let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
    let fileTypes = ['text/csv'];
 
    let selectedFile = e.target.files[0];
    if(selectedFile){

        console.log( selectedFile.type ) ;

        

      if(selectedFile&&fileTypes.includes(selectedFile.type)){
        setTypeError(null);
        let reader = new FileReader();

        reader.readAsText( selectedFile , "ISO-8859-2") ;
        //reader.readAsArrayBuffer(selectedFile);
        reader.onload= async(e)=>{

            console.log( "Antes de imprimir") ;


            console.log(   e.target.result  ) ;

            var data =  { body : e.target.result }

            setData( data ) ;

            //var data =  { body : "hola" } ;



        }
      }
      else{
        setTypeError('Favor de seleccionar archivo csv');
        setExcelFile(null);
      }
    }
    else{
      console.log('Please select your file');
    }
  }
  
  // submit event
  const handleFileSubmit= async(e)=>{
    e.preventDefault();

    setBack( true ) ;


    var res = await  axios.post( getGlobal() + "uploaddata" , data  , {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json' ,
          'Access-Control-Allow-Origin ': 'https://www.digital.digitalshopcenter.com.mx/'
        }
      })
      
      console.log( "Ya regrese ") ;
      console.log( JSON.stringify( res.data ) ) ;

      setBack( false ) ;

      setLoaded( true ) ;

  }

  function theNull(val)
  {
    console.log( "theNull = " + val ) ;
  }

  return (
    <div className="wrapper   text-center">

      <h3>Carga y Vista de Datos Cotizador</h3>

      {/* form */}
      <form className="form-group custom-form" onSubmit={handleFileSubmit}>
        <input type="file" className="form-control" required onChange={handleFile} />
        <button type="submit" className="btn btn-success btn-md">UPLOAD</button>
        {typeError&&(
          <div className="alert alert-danger" role="alert">{typeError}</div>
        )}
      </form>

      { back && <ExcelBack open={true} func={theNull}></ExcelBack> }
      { loaded && <ExcelLoadOk></ExcelLoadOk> }

      {/* view data */}
      <div className="viewer">
        {/* {excelData?(
          <div className="table-responsive">
            <table className="table">

              <thead>
                <tr>
                  {Object.keys(excelData[0]).map((key)=>(
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {excelData.map((individualExcelData, index)=>(
                  <tr key={index}>
                    {Object.keys(individualExcelData).map((key)=>(
                      <td key={key}>{individualExcelData[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        ):(
          <div>Aun no se ha subido el archivo!</div>
        )} */}
      </div>

    </div>
  );
}

export default ExcelLoad